@import "~antd/dist/antd.css";

.App {
  text-align: center;
}

body {
  background-color: #363636;
}

.ant-layout-content {
  padding: 25px;
  background-color: #363636;
}

.linkList li {
  margin-bottom: 20px;
}

a {
  color: rgb(243, 126, 3);
}

a:hover {
  color: rgb(247, 152, 52);
}
