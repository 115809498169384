.orange-button {
  outline: none;
  border: 1px solid transparent;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px 0px;
  margin: 0px 10px;
  padding: 6px 10px;
  min-height: 48px;
  background-color: rgb(243, 126, 3);
  color: rgb(255, 255, 255);
  border-radius: 4px;
  font-size: 20px;
}
.orange-button:hover {
  background-color: rgb(247, 152, 52);
}
