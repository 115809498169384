.ant-card {
  min-height: 200px;
  text-align: center;
  background-color: rgb(120, 119, 119) !important;
}

.ant-card p,
.ant-card-head-title {
  color: white;
}

.ant-card-hoverable:hover {
  box-shadow: 0 5px 12px 4px rgba(226, 224, 224, 0.4) !important;
}

.yeet {
  margin: 0px 10px;
  padding: 4px 10px;
}

.flex {
  display: flex;
  justify-content: center;
}
