.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: rgb(243, 126, 3) !important;
}

.ant-tabs-tab:hover {
  color: rgb(247, 152, 52) !important;
}

.ant-tabs-ink-bar {
  background: rgb(243, 126, 3) !important;
}

.ant-tabs-tab,
.ant-tabs-content {
  color: white;
}

.border {
  background-color: rgba(114, 76, 35);
  border-left: 6px solid rgb(243, 126, 3);
  padding: 10px;
  margin-bottom: 15px;
}

.sepFlexBox {
  display: flex;
  justify-content: space-between;
}
