* {
  box-sizing: border-box;
  font-family: sharp-sans;
  transition: all 0.2s ease-in-out
}

body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: sharp-sans;
  font-weight: lighter;
  src: url(./assets/fonts/samsungsharpsans.otf)
}

@font-face {
  font-family: sharp-sans;
  font-weight: normal;
  src: url(./assets/fonts/samsungsharpsans-medium.otf);
}

@font-face {
  font-family: sharp-sans;
  font-weight: bold;
  src: url(./assets/fonts/samsungsharpsans-bold.otf)
}